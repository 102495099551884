.modal {
    position: fixed!important;
    display: inherit!important;
    transform: scale(1);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 10;

}
.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 7;
    background-color: rgba(128, 128, 128, .7);
}
.modal-dialog {
    margin: 50px 0px 0px 0px;
    /* width: 500px; */
}
.show {
    /* position: absolute!important; */
}