html ,body {
  position: relative;
}
.App {
  text-align: center;
  height: 100%;
  position: relative;

}
.App__box {
  display: flex;
  flex-direction: column;
  min-height: 100%;

}